// extracted by mini-css-extract-plugin
export var activePage = "blog-module--active-page--3kgT4";
export var blogCardGrid = "blog-module--blog-card-grid--7F6h9";
export var blogCardGridOne = "blog-module--blog-card-grid-one--3Nu9W";
export var blogCardGridTwo = "blog-module--blog-card-grid-two--5oNwx";
export var blogImage = "blog-module--blog-image--hjQvj";
export var blogPagination = "blog-module--blog-pagination--3sZdV";
export var blogTags = "blog-module--blog-tags--4Ssd3";
export var blogTitle = "blog-module--blog-title--6qDIV";
export var featuredText = "blog-module--featured-text--3wtis";
export var gatsbyImageWrapper = "blog-module--gatsby-image-wrapper--3vsCC";
export var hTwo = "blog-module--h-two--1NrY5";
export var hero = "blog-module--hero--5jXML";
export var pageLink = "blog-module--page-link--6PwfV";
export var postImageContainer = "blog-module--post-image-container--3xIvI";
export var pubDate = "blog-module--pub-date--4Ia9a";
export var rule = "blog-module--rule--6YmwV";
export var tag = "blog-module--tag--6feB0";
export var v2 = "blog-module--v2--KdPqH";