import React from "react"
import { Link } from "gatsby"
import { Image } from "components/image/Image"
import { useLayout } from "contexts/components/LayoutContext"
import * as styles from "./blog.module.scss"

const BlogCard = ({ blog }) => {
  const { post_title, post_abstract, featured_image, slug, published_date } =
    blog

  const { linkedPagesUrlPathCodex } = useLayout()
  const pageSlug = linkedPagesUrlPathCodex[slug] || "404"
  const path = pageSlug.startsWith("/") ? pageSlug : `/${pageSlug}`

  const truncString = str => {
    if (str.length <= 155) {
      return str
    } else {
      return `${str.slice(0, 155)}...`
    }
  }

  return (
    <article>
      {featured_image && (
        <Image
          alt={featured_image.alternativeText}
          className={styles.blogImage}
          image={featured_image}
          objectFit="cover"
        />
      )}

      <h2 className={styles.blogTitle}>
        <Link to={path}>{post_title}</Link>
      </h2>
      <p className={styles.pubDate}>{published_date}</p>
      <p className={styles.featuredText}>
        {truncString(post_abstract)}
        <Link to={path}>Read More</Link>
      </p>
    </article>
  )
}

export default BlogCard
