import React from "react"
import FormBuilder from "./FormBuilder"
import { IFormBanner } from "./formBuilder-types"

export const GlobalFooterFormBanner = ({
  custom_id,
  ...FormBuilderProps
}: IFormBanner) => {
  const id = custom_id || "form-banner"

  return (
    <section className="form-banner-container" id={id}>
      <div className="container">
        <FormBuilder {...FormBuilderProps} />
      </div>
    </section>
  )
}
