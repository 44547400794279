import React from "react"
import { graphql, useStaticQuery, PageProps } from "gatsby"
import Layout from "components/layout"
import SEO from "components/seo/seo"
import ContentBlocks from "components/content-blocks/ContentBlocks"
import GlobalFooter from "components/global-footer/GlobalFooter"
import GoogleRating from "components/google-rating-banner/GoogleRating"
import SimpleHero from "components/simple-hero/SimpleHero"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import { EMPTY_PAGE } from "assets/js/EmptyPage"
type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface NotFoundPageProps extends PageProps {
  pageContext: PageContext
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  location,
  pageContext,
}) => {
  const { pathname } = location
  const { contentUrlPathCodex, linkedPagesUrlPathCodex } = pageContext
  const { googleReviewsUrl } = useIntegrationsSettings()
  const data = useStaticQuery(get404)
  const [page] = data.allStrapiPage.nodes
  const { content, custom_tracking_number, global_footer, hero } =
    page ?? EMPTY_PAGE

  return (
    <>
      <Layout
        contentUrlPathCodex={contentUrlPathCodex}
        customTrackingNumber={custom_tracking_number}
        linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
        location="/404"
        pathName={pathname}
      >
        <SEO title="404: Not found" />
        {hero && <SimpleHero banner={hero} />}
        {googleReviewsUrl && <GoogleRating url={googleReviewsUrl} />}

        {content?.length > 0 && <ContentBlocks strapiContent={content} />}

        {global_footer?.content?.length > 0 && (
          <GlobalFooter content={global_footer.content} />
        )}
      </Layout>
    </>
  )
}

const get404 = graphql`
  query FourOhFourPage {
    allStrapiPage(filter: { slug: { eq: "404" } }) {
      nodes {
        custom_tracking_number
        hero {
          heading
          sub_heading_rich {
            data {
              sub_heading_rich
            }
          }
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCTABannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiLogoSliderFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceAreaGroupFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiLegacyPestLibraryFragment
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`

export default NotFoundPage
