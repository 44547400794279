import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from "./BlogCard"
import * as styles from "./blog.module.scss"

export const BlogFooter = ({ heading, parentSlug }) => {
  const { posts } = useStaticQuery(getBlogBannerFooterPosts)

  const getBlogArticles = posts => {
    if (posts?.edges?.length === 0) return null

    return posts?.edges?.map(({ node }, index) => {
      return <BlogCard key={`${node.id}-${index}`} blog={node} />
    })
  }

  return (
    <div className="section-padding">
      <h2>{heading}</h2>
      <div
        className={`container ${styles.blogCardGrid} ${
          posts?.edges?.length === 1
            ? styles.blogCardGridOne
            : posts?.edges?.length === 2
            ? styles.blogCardGridTwo
            : ""
        }`}
      >
        {getBlogArticles(posts)}
      </div>
    </div>
  )
}

const getBlogBannerFooterPosts = graphql`
  query getBlogBannerFooterPosts {
    posts: allStrapiBlog(
      sort: { fields: published_date, order: DESC }
      limit: 2
      filter: { post_url: { ne: "demo-blog" } }
    ) {
      edges {
        node {
          id
          post_title
          post_abstract
          published_date(formatString: "MMMM DD, YYYY")
          slug: post_url
          featured_image {
            ...StrapiImageFragment
          }
        }
      }
    }
  }
`

export const blogGlobalFooterquery = graphql`
fragment StrapiBlogBannerGlobalFooterFragment on STRAPI__COMPONENT_GLOBAL_FOOTER_GLOBAL_FOOTER_BLOG_BANNER {
    id
    strapi_component
    eyebrow_text
    heading
    sub_heading
  }
`
