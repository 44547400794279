import React, { useMemo } from "react"
import { shuffle } from "../../assets/js/helper"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"
import ReviewImage from "./ReviewImage"
import { Content } from "components/content/Content"

const StaticReview = () => {
  const { staticReviews } = useIntegrationsSettings()
  const getRating = rating => {
    switch (rating) {
      case "n1 Star":
        return "★"
      case "n2 Stars":
        return "★★"
      case "n3 Stars":
        return "★★★"
      case "n4 Stars":
        return "★★★★"
      case "n5 Stars":
        return "★★★★★"
      default:
        break
    }
  }

  const randomReview = useMemo(() => {
    const review = shuffle(staticReviews)
    return review
  }, [staticReviews])

  const {
    date,
    rating,
    location,
    review,
    reviewer,
    reviewImage,
    reviewSourceLink,
  } = randomReview

  return (
    <>
      <div className="container singleReview_container">
        <div className="singleReview_info">
          <h2>Customer Reviews</h2>
          <div className="review_quote">
            <Content content={review} />
          </div>
          <div className="review_details">
            <div className="reviewer">
              {reviewer}
              <span>&nbsp;</span>
            </div>

            {location.length > 0 && <span>{"- " + location}</span>}

            <a href={reviewSourceLink} target="_blank" rel="noreferrer">
              &nbsp;{"| "}
              Read Review
            </a>
          </div>
          <span className="review_stars">{getRating(rating)}</span>
        </div>
        <ReviewImage image={reviewImage} />
      </div>
    </>
  )
}

export default StaticReview
