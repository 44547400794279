import React from "react"
import { graphql } from "gatsby"
import SingleReview from "components/reviews/SingleReview"
import StaticReview from "components/reviews/StaticReview"
import { useIntegrationsSettings } from "hooks/useIntegrationsSettings"

interface GlobalFooterReviewsProps {}

export const GlobalFooterReviews = ({}: GlobalFooterReviewsProps) => {
  const { hasFieldroutesReviews, staticReviews } = useIntegrationsSettings()

  if (hasFieldroutesReviews) {
    return (
      <div className="container">
        <SingleReview />
      </div>
    )
  }

  if (staticReviews.length > 0) {
    return (
      <div className="container">
        <StaticReview />
      </div>
    )
  }
  return <></>
}

export const reviewPositionGlobalFooterquery = graphql`
  fragment StrapiReviewGlobalFooterFragment on STRAPI__COMPONENT_REVIEWS_REVIEW_POSITION {
    id
    strapi_component
    Review
  }
`
