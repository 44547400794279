import React from "react"
import { graphql } from "gatsby"
import CTABanner from "components/cta-banner/CTABanner"
import { BlogFooter } from "components/blog/BlogFooter"
import FormBuilder from "components/forms/FormBuilder"
import { GlobalFooterFormBanner } from "components/forms/GlobalFooterFormBanner"
import { AffiliationSliderGlobalFooter } from "components/affiliation-slider/AffiliationSliderGlobalFooter"
import { GlobalFooterReviews } from "./GlobalFooterReviews"

const GlobalFooter = ({ content }) => {
  const components = {
    "content-block.cta-banner": CTABanner,
    "content-block.footer-logo-slider": AffiliationSliderGlobalFooter,
    "forms.form": GlobalFooterFormBanner,
    "global-footer.global-footer-blog-banner": BlogFooter,
    "reviews.review-position": GlobalFooterReviews,
  }

  return content.map(component => {
    if (typeof components[component.strapi_component] !== "undefined") {
      const Component = components[component.strapi_component]

      const locationComponentParser =
        component.strapi_component === "forms.form"
          ? { locationComponentParser: "global-footer" }
          : {}
      return (
        <Component
          key={component.id}
          {...component}
          {...locationComponentParser}
        />
      )
    }
    return <p key={Math.random()}>Not yet queried</p>
  })
}

export const strapiQuery = graphql`
  fragment StrapiGlobalFooterFragment on STRAPI_GLOBAL_FOOTER {
    id
    content {
      ...StrapiBlogBannerGlobalFooterFragment
      ...StrapiCTABannerFragment
      ...StrapiFormGlobalFooterFragment
      ...StrapiLogoSliderGlobalFooterFragment
      ...StrapiReviewGlobalFooterFragment
    }
  }
`

export default GlobalFooter
