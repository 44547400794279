import React from "react"
import { Link, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { useMainOfficeNumber } from "hooks/useMainOfficeNumber"
import { useStrapiButtons } from "hooks/useStrapiButtons"
import { ButtonParser } from "components/button/ButtonParser"
import * as styles from "./cta-banner.module.scss"

const CTABanner = ({
  background_image,
  buttons,
  cta_description,
  heading,
  reusable_button_group,
}) => {
  const { mainOfficeNumber } = useMainOfficeNumber({})
  const strapiButtons = useStrapiButtons(buttons, reusable_button_group)
  const max2Buttons = strapiButtons
    ? strapiButtons?.filter((_, index) => index < 2)
    : []

  const trackingPhone = mainOfficeNumber || "(207) 888-8888"

  if (background_image) {
    const backgroundImageUrl = background_image?.url

    const backgroundImageCSSVariable = useBackgroundImage({
      backgroundImageUrl,
      variable: "cta",
    })

    return (
      <div className={styles.ctaBannerBg}>
        {backgroundImageUrl && (
          <div
            className={styles.ctaBannerBgImage}
            style={backgroundImageCSSVariable}
          />
        )}
        <div className={styles.flexible}>
          {heading && <h2 className={styles.heading}>{heading}</h2>}

          {trackingPhone && (
            <a href={`tel:${trackingPhone}`} className={styles.phone}>
              {trackingPhone}
            </a>
          )}

          {cta_description && (
            <p className={styles.description}>{cta_description}</p>
          )}

          {!!max2Buttons?.length && (
            <div className={`flex-column-center ${styles.buttonContainer}`}>
              <ButtonParser
                buttons={max2Buttons}
                firstButtonClassName="white-accent-ghost-button"
                secondButtonClassName="white-accent-ghost-button"
                firstButtonId="hero-cta"
              />
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.ctaBannerBg}>
        <div className="container">
          {heading && <h2 className={styles.heading}>{heading}</h2>}

          {trackingPhone && (
            <a href={`tel:${trackingPhone}`} className={styles.phone}>
              {trackingPhone}
            </a>
          )}

          {cta_description && (
            <p className={styles.description}>{cta_description}</p>
          )}

          {!!max2Buttons?.length && (
            <div className={`flex-column-center ${styles.buttonContainer}`}>
              <ButtonParser
                buttons={max2Buttons}
                firstButtonClassName="white-accent-ghost-button"
                secondButtonClassName="white-accent-ghost-button"
                anchorLinkId="hero-cta"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export const strapiQuery = graphql`
  fragment StrapiCTABannerFragment on STRAPI__COMPONENT_CONTENT_BLOCK_CTA_BANNER {
    strapi_component
    id
    cta_description
    eyebrow_text
    heading
    sub_heading
    background_image {
      ...StrapiBaseImageFragment
      localFile {
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, speed: 10, quality: 30 }
            webpOptions: { quality: 7 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
          )
        }
        publicURL
      }
    }
    buttons {
      ...StrapiButtonFragment
    }
    reusable_button_group {
      ...StrapiReusableButtonFragment
    }
  }
`

export const strapiCTABannerGlobalFooterQuery = graphql`
  fragment StrapiCTABannerGlobalFooterFragment on STRAPI__COMPONENT_CONTENT_BLOCK_CTA_BANNER {
    id
    strapi_component
    eyebrow_text
    heading
    sub_heading
    cta_description
    buttons {
      ...StrapiButtonFragment
    }
    background_image {
      alternativeText
      height
      url
      width
    }
  }
`

export default CTABanner
